import styled, { css } from 'styled-components';
import { isTabletOrLarger } from 'app/globalStyles/screens';
import {
  setFont,
} from 'app/globalStyles/variables';
import { Section, SectionHeading } from './common.styles';

export const FeaturesHeading = styled<any>(SectionHeading)`
  margin-top: 0;
  margin-bottom: 10px;
  color: ${({ theme: { colors } }) => colors.colorTitle};
  ${isTabletOrLarger(css`
    min-width: 100%;
    width: auto;
  `)};
`;

export const FeatureSpan = styled.p`
  font-family: ${setFont.main};
  line-height: 1.6;
  letter-spacing: -0.3px;
  color: ${({ theme: { colors } }) => colors.colorText};
  margin: 10px auto;
  font-size: 16px;
  padding: 10px 10px;
  ${isTabletOrLarger(css`
    font-size: 18px;
    max-width: initial;
  `)};
`;

export const FeaturesSection = styled<any>(Section)`
  width: 90%;
  margin: 0 auto !important;
  flex-direction: column-reverse;
  ${isTabletOrLarger(css`
    width: 76%;
    justify-content: space-between;
  `)};
`;

export const ImageSection = styled.div`
  padding: 0;
  text-align: center;
  width: 90%;
  ${isTabletOrLarger(css`
    width: 50%;
    justify-content: space-between;
  `)};
`;

export const CopySection = styled.div`
  align-self: center;
  width: 90%;
  ${isTabletOrLarger(css`
    width: 40%;
    justify-content: space-between;
  `)};
`;

export const Button = styled.button`
    width: 50px;
    padding: 5px;
    border: none;
    margin: 0 3px;
    outline: none;
    background: ${({ theme: { colors } }) => colors.colorBackground};
`;

export const screenDisplayStyles = css`
  height: 100%;
  width: 100%;
  margin: 30px auto;
  border: none;
`;
