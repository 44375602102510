/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { ArrowLeft, ArrowRight } from 'styled-icons/typicons';
import { ChevronLeft, ChevronRight } from 'styled-icons/boxicons-regular';

import {
  Heading,
  SubHeading,
  CardWrapper,
  CardHeading,
  CardContent,
  StyledSlider,
  NextIconButton,
  PrevIconButton,
  TestimonialCard,
  ReviewLink,
  CarouselContainer,
  StyledTestimonials,
} from 'static/landing/components/_styles_/Testimonials.styles';
import testimonials from 'static/landing/testimonials.json';

interface IconProps {
  id?: string;
  className?: string;
}

type Testimonial = {
  id: number;
  author: string;
  link: string;
  content: string;
}

const NextIcon = ({ id, className, ...rest }: IconProps) => (
  <NextIconButton {...rest}>
    <ChevronRight />
  </NextIconButton>
);
const PrevIcon = ({ id, className, ...rest }: IconProps) => (
  <PrevIconButton {...rest}>
    <ChevronLeft />
  </PrevIconButton>
);

const Testimonials = (): JSX.Element => {
  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 150,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    nextArrow: <NextIcon />,
    prevArrow: <PrevIcon />,
  };
  return (
    <StyledTestimonials>
      <Heading>Join 10,000+ satisfied users</Heading>
      <SubHeading>
        <span>Some of them shared their love</span>
        <span role="img" aria-label="love">❤️</span>
      </SubHeading>
      <CarouselContainer>
        <StyledSlider {...settings}>
          {(testimonials as Array<Testimonial>).map((testimonial) => (
            <CardWrapper key={testimonial.link}>
              <TestimonialCard>
                <CardHeading>{testimonial.author}</CardHeading>
                <CardContent>{`"${testimonial.content}"`}</CardContent>
                <ReviewLink href={testimonial.link}>
                  <span>View Review</span>
                  <ArrowRight height="24px" width="24px" />
                </ReviewLink>
              </TestimonialCard>
            </CardWrapper>
          ))}
        </StyledSlider>
      </CarouselContainer>
    </StyledTestimonials>
  );
};

export default Testimonials;
