import styled, { css } from 'styled-components';
import {
  isTabletOrSmaller,
  isTabletOrLarger,
  isDesktopOrLarger,
} from 'app/globalStyles/screens';
import { setFlex } from 'app/globalStyles/variables';
import {
  SectionHeading,
} from 'static/landing/components/_styles_/common.styles';
import Link from 'next/link';

export const PlatformBadges = styled.div`
  margin-left: 10px;
  padding-top: 10px;
  ${setFlex()};
  ${isTabletOrLarger`
    ${setFlex({ x: 'flex-start' })};
  `};
  ${isDesktopOrLarger(
    css`
      margin-left: 0;
    `
  )}
`;

export const BadgeLink = styled(Link)`
  &:last-child {
    margin-left: 5px;
  }
`;

export const Badge = styled.img`
  height: 45px;
  width: auto;
  ${isTabletOrSmaller` height: 40px `}
`;

// @ts-ignore
export const GPlayBadge = styled(Badge)`
  height: 67px;
  ${isTabletOrSmaller` height: 60px `}
`;

// @ts-ignore
export const Heading = styled(SectionHeading)`
  margin-bottom: 20px;
  margin-top: 60px;
  ${isTabletOrLarger`
     margin-top: initial;
  `};
`;

export const Subheading = styled.p`
  font-size: 18px;
  line-height: 1.6;
  letter-spacing: -0.3px;
  padding: 10px 0;
  ${isTabletOrLarger(css`
    text-align: initial;
  `)};
`;
