import styled, { css } from 'styled-components';
import { isTabletOrLarger, isLargeTablet, isDesktopOrLarger } from 'app/globalStyles/screens';
import { setFlex, setFont } from 'app/globalStyles/variables';
import { ArrowDownward } from 'styled-icons/material';

export const NavigateDownIcon = styled.div`
  display: none;
  color: ${({ theme: { colors } }) => colors.colorText};
  width: 50px;
  height: 50px;
  position: absolute;
  top: 90vh;
  left: 50%;
  background: ${({ theme: { colors } }) => colors.colorBackgroundDark};
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  outline: 0;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  :hover {
    opacity: .9;
    background: ${({ theme: { colors } }) => colors.colorBackground}
  }
  ${isLargeTablet`
    display: initial;
  `};
  ${isDesktopOrLarger`
    display: initial;
  `};
`;

export const ArrowIcon = styled(ArrowDownward)`
`;

export const Section = styled.section`
   margin-bottom: 6.25vw;
  ${setFlex()};
  flex-direction: column;
  margin-left: unset;
  ${isTabletOrLarger(css`
      ${setFlex({ x: 'center', y: 'start' })};
      flex-direction: row;
      margin-left: initial;
  `)};
`;

export const SectionHeading = styled.h2`
  font-family: ${setFont.headings};
  font-weight: 400;
  color: ${({ theme: { colors } }) => colors.colorTitle};
  font-size: 3.2rem;
  line-height: 1.3;
  letter-spacing: -0.291667px;
  text-align: center;
  ${isTabletOrLarger(css`
    font-size: 2.70833vw;
    line-height: 3.59375vw;
    letter-spacing: -0.722222px;
    text-align: initial;
  `)};
`;
