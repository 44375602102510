import Slider from 'react-slick';
import {
  isDesktopOrLarger,
  isTablet,
  isTabletOrLarger,
} from 'app/globalStyles/screens';
import Link from 'next/link';
import styled, { css } from 'styled-components';
import { setFlex } from 'app/globalStyles/variables';
import { Section, SectionHeading } from './common.styles';

export const StyledSlider = styled(Slider)`
  width: 100%;
  max-height: 600px;
  ${isTabletOrLarger(css`
    max-width: 700px;
  `)}
`;

export const StyledTestimonials = styled<any>(Section)`
  width: 100%;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.colorBackgroundDark};
  height: 600px;

  ${isTablet(css`
    margin: 20px 0;
  `)};
  
  ${isTabletOrLarger(css`
    ${setFlex()};
    flex-direction: column; 
`)};
`;

export const Heading = styled<any>(SectionHeading)`
  text-align: center;
  margin: 0 1rem;
`;

export const SubHeading = styled.span`
  font-size: 18px;
  margin-top: 1rem;
`;

export const CarouselContainer = styled.div`
  width: 100%;
  ${setFlex({ x: 'center', y: 'center' })};
  position: relative;
  margin-bottom: 5rem;

  .slick-dots {
    bottom: 1rem;
    position: relative;
    li {
      width: 15px;
      height: 15px;
      button {
        color: ${(p) => p.theme.colors.colorPrimary1};
        &:before {
          font-size: 12px;
        }
      }
      .slick-active {
        box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
      }
    }
  }
  ${isTablet(css`
    padding: 0 6rem;
  `)}
  ${isDesktopOrLarger(css`
    padding: 0 15rem;
    width: 100%;
  `)}
`;

export const CardWrapper = styled.div`
  padding: 1rem;
  ${setFlex({ x: 'center', y: 'center' })};
  outline: none;
  ${isTabletOrLarger(
    css`
      padding: 3rem 4rem;
    `,
  )}
`;

export const TestimonialCard = styled.div`
  background: ${(p) => p.theme.colors.colorBackground};
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: relative;
  padding: 2rem;
  margin: 3rem auto;
  min-height: 230px;
  width: calc(90% - 55px);
  display:flex;
  flex-direction:column;

  ${isTabletOrLarger(
    css`
      max-width: 600px;
    `,
  )}
`;

export const CardHeading = styled.h4`
  color: ${(p) => p.theme.colors.colorTitle};
  font-weight: 500;
  font-size: 22px;
`;

export const CardContent = styled.p`
  font-size: 18px;
  margin: 1.5rem 0;
  color: ${(p) => p.theme.colors.colorText};
`;

export const ReviewLink = styled(Link)`
  font-size: 14px;
  text-decoration: none;
  margin-top: auto;
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors.colorText};
  opacity: 0.8;
`;

const navigationIconStyles = css`
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  outline: none;
  z-index: 1000;
  padding: 1rem;
  background: ${(p) => p.theme.colors.colorPrimary4};
  color: ${(p) => p.theme.colors.colorText};
  &:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s linear;
  }
`;

export const NextIconButton = styled.button`
  ${navigationIconStyles};
  right: 5px;
  ${isDesktopOrLarger(css`
    right: -100px;
    display: block !important;
  `)}
`;

export const PrevIconButton = styled.button`
  ${navigationIconStyles};
  left: 5px;
  ${isDesktopOrLarger(css`
    left: -100px;
    display: block !important;
  `)}
`;
