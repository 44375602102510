import React from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  HighlightFill,
  ShareFill,
  WriteFill,
  BookFill,
  Security,
} from 'static/assets';
import {
  NavigateDownIcon,
  ArrowIcon,
} from 'static/landing/components/_styles_/common.styles';
import * as S from './_styles_/Features.styles';
import featureData from '../features.json';

const featureIcons = {
  write: () => <WriteFill css={S.cardIconStyle} />,
  read: () => <BookFill css={S.cardIconStyle} />,
  secure: () => <Security css={S.cardIconStyle} />,
  highlight: () => <HighlightFill css={S.cardIconStyle} />,
  share: () => <ShareFill css={S.cardIconStyle} />,
};

export interface Props {
  title: string;
  description: string;
  type: 'write' | 'read' | 'secure' | 'highlight' | 'share';
  style?: any;
}

export const FeatureCardItem = ({ title, description, type, style }: Props): JSX.Element => {
  const FeatureIcon = featureIcons[type];
  return (
    <S.FeatureCard css={style}>
      <FeatureIcon />
      <S.CardTitle>{title}</S.CardTitle>
      <S.CardDescription>{description}</S.CardDescription>
    </S.FeatureCard>
  );
};

const Features = (): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const htmlElRef = React.createRef<HTMLElement>();
  const executeScroll = () =>
    window.scrollTo({
      behavior: 'smooth',
      top:
        htmlElRef.current?.offsetHeight !== undefined
          ? htmlElRef.current?.offsetHeight + 180
          : htmlElRef.current?.offsetHeight,
    });

  const SectionHeading = () => (
    <S.FeaturesHeading>
      What can you do with Stoic Bible?
    </S.FeaturesHeading>
  );

  return (
    <React.Fragment>
      <NavigateDownIcon onClick={() => executeScroll()}>
        <ArrowIcon />
      </NavigateDownIcon>
      <S.FeaturesSection ref={htmlElRef}>
        {isMobile ? (
          <>
            {SectionHeading()}
            <FeatureCardItem
              title={featureData.read.title}
              description={featureData.read.description}
              type="read"
            />
            <FeatureCardItem
              title={featureData.write.title}
              description={featureData.write.description}
              type="write"
            />
            <FeatureCardItem
              title={featureData.secure.title}
              description={featureData.secure.description}
              type="secure"
            />
            <FeatureCardItem
              title={featureData.highlight.title}
              description={featureData.highlight.description}
              type="highlight"
            />
            <FeatureCardItem
              title={featureData.share.title}
              description={featureData.share.description}
              type="share"
            />
          </>
        ) : (
          <>
            <S.FeatureColumn>
              {SectionHeading()}
              <FeatureCardItem
                title={featureData.read.title}
                description={featureData.read.description}
                type="read"
              />
            </S.FeatureColumn>
            <S.FeatureColumn>
              <FeatureCardItem
                title={featureData.write.title}
                description={featureData.write.description}
                type="write"
              />
              <FeatureCardItem
                title={featureData.secure.title}
                description={featureData.secure.description}
                type="secure"
              />
            </S.FeatureColumn>
            <S.FeatureColumn>
              <FeatureCardItem
                title={featureData.highlight.title}
                description={featureData.highlight.description}
                type="highlight"
              />
              <FeatureCardItem
                title={featureData.share.title}
                description={featureData.share.description}
                type="share"
              />
            </S.FeatureColumn>
          </>
        )}
      </S.FeaturesSection>
    </React.Fragment>
  );
};

export default Features;
