import React from 'react';
import { routeAs } from 'common/utils/routes';
import StartLearningCTA from 'static/landing/components/StartLearningCTA';
import * as S from './_styles_/Books.styles';

// TODO: We'll need to fetch this data from the BE.
const booksData = [
  {
    id: 'meditations',
    title: 'Meditations',
    firstChapterSlug: 'meditations-book-1',
  },
  {
    id: 'letters-from-a-stoic-vol-1',
    title: 'Letters From a Stoic Vol I',
    firstChapterSlug: 'letters-from-a-stoic-1-on-saving-time',
  },
  {
    id: 'letters-from-a-stoic-vol-2',
    title: 'Letters From a Stoic Vol II',
    firstChapterSlug: 'letters-from-a-stoic-66-on-various-aspects-of-virtue',
  },
  {
    id: 'letters-from-a-stoic-vol-3',
    title: 'Letters From a Stoic Vol III',
    firstChapterSlug:
      'letters-from-a-stoic-93--on-the-quality--as-contrasted-with-the-length--of-life',
  },
  {
    id: 'enchiridion',
    title: 'Enchiridion',
    firstChapterSlug: 'enchiridion-1',
  },
  {
    id: 'on-the-shortness-of-life',
    title: 'On The Shortness of Life',
    firstChapterSlug: 'on-the-shortness-of-life-1',
  },
  {
    id: 'poems',
    title: 'Poems',
    firstChapterSlug: 'poems-invictus',
  },
  {
    id: 'on-peace-of-mind',
    title: 'On Peace of Mind',
    firstChapterSlug: 'on-peace-of-mind-1---serenus-',
  },
  {
    title: 'and lots more.',
    firstChapterSlug: 'meditations-book-1',
  },
];

const BooksSection = (): JSX.Element => (
  <S.BooksSection>
    <S.Heading>Access timeless curated works</S.Heading>
    <S.BookRows>
      {booksData.map((bookInfo) => (
        <S.BookCard
          key={bookInfo.id || 'books'}
          href={bookInfo.id ? routeAs.book(bookInfo.id) : routeAs.books}
        >
          {bookInfo.title}
        </S.BookCard>
      ))}
    </S.BookRows>
    <StartLearningCTA showArrow={false} />
  </S.BooksSection>
);

export default BooksSection;
