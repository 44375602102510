import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { routeTo } from 'common/utils/routes';
import { AuthModal } from 'modules/auth';
import { useRouter } from 'next/router';
import {
  HeroSection,
  HeroImage,
  HeroMain,
  HeroHeader,
  HeroSubHeader,
  HeroButtonsWrapper,
  PrimaryCTA,
  CTAArrow,
  SecondaryCTA,
} from 'static/landing/components/_styles_/Hero.styles';

export interface Props {
  isUserAuthenticated: boolean;
}

const Hero = ({ isUserAuthenticated }: Props): JSX.Element => {
  const router = useRouter();
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [visibleAuthModal, setVisibleAuthModal] = useState<
    'signup' | 'signin' | undefined
  >(undefined);

  const closeAuthModal = () => setVisibleAuthModal(undefined);
  const openAuthModal = () => setVisibleAuthModal('signup');

  const handlePrimaryCTA = () => {
    if (isUserAuthenticated) {
      router.push(routeTo.membership);
    } else {
      openAuthModal();
    }
  };

  return (
    <HeroSection>
      {isMobile && <HeroImage />}
      <HeroMain>
        <HeroHeader>
          Embrace Stoic Wisdom & build unbreakable life-transforming discipline
        </HeroHeader>
        <HeroSubHeader>
          If self-help books, online coaches, and podcasts aren’t helping you
          achieve your goals, it may be because they lack the key ingredient
          that Stoic Bible offers: a philosophy of life.
        </HeroSubHeader>
        <HeroButtonsWrapper>
          <PrimaryCTA href="#" onClick={handlePrimaryCTA} block>
            {isUserAuthenticated ? 'Become a Member' : 'Sign Up for Free'}
          </PrimaryCTA>
          {!!visibleAuthModal && (
            <AuthModal
              closeModal={closeAuthModal}
              visibleAuthModal={visibleAuthModal}
            />
          )}
          <SecondaryCTA href={routeTo.read} block>
            <span>
              {isUserAuthenticated ? 'Continue Learning' : 'Start Learning'}
            </span>
            <CTAArrow />
          </SecondaryCTA>
        </HeroButtonsWrapper>
      </HeroMain>
    </HeroSection>
  );
};

export default Hero;
