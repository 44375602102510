import styled, { css } from 'styled-components';
import { isTablet, isTabletOrLarger } from 'app/globalStyles/screens';
import {
  setFlex,
  setFont,
} from 'app/globalStyles/variables';
import { Section, SectionHeading } from './common.styles';

export const FeaturesHeading = styled<any>(SectionHeading)`
  margin-top: 0;
  text-align: center;
  margin-bottom: 50px;
  max-width: 100%;
  ${isTabletOrLarger(css`
    margin-bottom: 99px;
    max-width: 20vw;
    text-align: center;
  `)};
`;

// @ts-ignore
export const FeaturesSection = styled(Section)`
   width: 90%;
   ${setFlex()};
   margin: 0 auto;
   ${isTabletOrLarger(css`
       margin: 0 auto 80px auto;
       ${setFlex()};
   `)}
`;

export const FeatureColumn = styled.div`
  flex-direction: column;
  margin-right: 6.25vw;
  position: relative;
  &:last-of-type {
   margin-right: 0;
  };
  &:first-of-type {
    transform: translateY(-21%);
   };
`;

export const CardTitle = styled.h3`
  font-family: ${setFont.headings};
  font-size: 2rem;
  line-height: 2.8rem;
  text-align: center;
  font-weight: 400;
  margin-bottom: 5.556%;
  color: ${({ theme: { colors } }) => colors.colorTitle};
  letter-spacing: -0.291667px;
  ${isTabletOrLarger(css`
    font-size: 2.4rem;
    line-height: 1.9270833333vw;
  `)};
`;

export const FeatureCard = styled.div`
  height: 19.75vw;
  width: 100%;
  min-height: 262px;
  ${setFlex()};
  margin-bottom: 40px;
  overflow: auto;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  border-top: 2px solid ${({ theme: { colors } }) => colors.colorPrimary2};
  border-radius: 4.46262px;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  padding: 48px 24px;
  flex-direction: column;
  box-sizing: border-box;
  &:first-child {
    margin-bottom: 7.2916666667vw;
  };

  ${isTabletOrLarger(css`
    width: 20vw;
    min-height: initial;
    ${setFlex({ x: 'start' })};
    margin-bottom: initial;
  `)};

  ${isTablet(css`
    width: 22vw;
    height: 22vw;
    padding: 2.96875vw 10px;
 `)};
`;

export const CardDescription = styled.div`
  text-align: center;
  font-size: 1.6rem;
  line-height: 2.7rem;
  padding: 0 20px;
  letter-spacing: -0.624766px;
  ${isTabletOrLarger(css`
    font-size: 16px;
    line-height: 1.6;
    padding: initial;
    letter-spacing: -0.3px;
  `)};
  ${isTablet(css`
    font-size: 1.3rem;
  `)};
`;

export const cardIconStyle = css`
  height: 55px;
  width: 55px;
  min-height: 25.4444444443%;
  margin: 0 auto 8.3% auto;
  path {
    fill: ${({ theme: { colors } }) => colors.colorPrimary3};
  }
  ${isTabletOrLarger(css`
    height: 25.4444444443%;
    width: 25.4444444443%;
  `)};
`;
