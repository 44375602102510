import React from 'react';
import { useMediaQuery } from 'react-responsive';
import * as S from 'static/landing/components/_styles_/NewFeatures.styles';

const NewFeaturesSection = (): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <S.FeaturesSection>
      <S.ImageSection>
        {isMobile ? (
          <img src="/MobileHero.png" css={S.screenDisplayStyles} alt="Reading on mobile" />
        ) : (
          <img src="/LaptopReading.png" css={S.screenDisplayStyles} alt="Reading on desktop" />
        )}
      </S.ImageSection>
      <S.CopySection>
        <S.FeaturesHeading>Upgrade your life with ancient wisdom.</S.FeaturesHeading>
        <S.FeatureSpan>
          Gain immeasurable wisdom from the most powerful and influential men of
          the ancient world with a distraction-free,
          fully customizable reader and journaling app and do more
          than discover ancient wisdom—put it into action.
        </S.FeatureSpan>
        <S.FeatureSpan>
          Get the most from the Stoic Bible with time-saving features that will allow
          you to keep track of your favorite quotes and highlights, create notes,
          and view the list of highlights from each book.
        </S.FeatureSpan>
      </S.CopySection>
    </S.FeaturesSection>
  );
};
export default NewFeaturesSection;
