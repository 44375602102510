import styled, { css } from 'styled-components';
import {
  isTablet, isDesktopOrLarger, isTabletOrLarger
} from 'app/globalStyles/screens';
import { setFlex } from 'app/globalStyles/variables';
import Link from 'next/link';
import { SectionHeading, Section } from './common.styles';
import { PrimaryCTA, CTAArrow } from './Hero.styles';

export const BooksSection = styled<any>(Section)`
  width: 100%;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
  background: ${({ theme: { colors } }) => colors.colorBackgroundDark};
  ${isTablet(css`
    margin: 20px 0;
  `)};
  ${isTabletOrLarger(css`
      flex-direction: column;
      ${setFlex()};
  `)};
`;

export const ReadingCTA = styled<any>(PrimaryCTA)`
  margin: 20px;
  background: ${({ theme: { colors } }) => colors.colorPrimary1};
  ${isTablet`
    width:20%;
  `};
`;

export const ReadingCTAArrow = styled<any>(CTAArrow)`
  color: white;
`;

export const Heading = styled<any>(SectionHeading)`
  text-align: center;
  margin-bottom: 30px;
`;

export const BookRows = styled.div`
  width: 90%;
  min-width: 320px;
  ${setFlex()};
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  padding: 5px;
  ${isTabletOrLarger(css`
     width: 80%;
  `)}
  ${isTablet`
      width: 90%;
  `};
  ${isDesktopOrLarger`
    width: 85%;
  `};
`;

export const BookCard = styled(Link)`
 ${setFlex()};
  width: 100%;
  height: 50px;
  padding: 10px;
  max-height: 50px;
  text-align: center;
  border-radius: 5px;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  text-decoration: none;
  border: 1px solid ${({ theme: { colors } }) => colors.colorBorder};
  color: inherit;
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: -0.3px;
  line-height: unset;
  min-height: unset;
  margin: 0 auto 10px;
  &:nth-child(9) {
    width: 100%;
  }
  ${isTabletOrLarger(css`
      width: 20%;
      line-height: 3.0020833333vw;
      min-height: initial;
      margin-right: 20px;
      margin-top: 1.5625vw;
      margin-bottom: 0.3645833333vw;
      margin-left: 0.9375vw;
      &:nth-child(9) {
        width: 43%;
      };
  `)};

  ${isTablet`
    height: 50px;
    line-height: unset;
    font-size: 1.4rem;
  `};
  ${isDesktopOrLarger`
    height: 50px;
    line-height: unset;
    font-size: 1.6rem;
  `};
`;
