import React from 'react';
import styled, { css } from 'styled-components';
import {
  Badge,
  BadgeLink,
  GPlayBadge,
  Heading,
  PlatformBadges,
  Subheading,
} from 'static/landing/components/_styles_/MobileAppHero';
import { useMediaQuery } from 'react-responsive';
import { isTabletOrLarger, isDesktopOrLarger } from 'app/globalStyles/screens';

const gplayAppLink = 'https://play.google.com/store/apps/details?id=com.stoicbible';
const appStoreAppLink = 'https://apps.apple.com/us/app/stoic-bible/id1542454799#?platform=iphone';

const MobileAppSection = styled.section`
  margin: 0 auto;
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 60px;
  ${isDesktopOrLarger(css`
    width: 80%;
    margin-top: 40px;
  `)};
`;

const HeroCopySection = styled.div`
  width: 90%;
  ${isDesktopOrLarger(css`
    width: 40%;
  `)};
`;

const HeroImage = styled.img`
  height: auto;
  ${isTabletOrLarger(
    css`
      width: 450px;
    `,
  )};
`;

export interface Props {
  className?: string;
}

const MobileAppHero = ({ className }: Props): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <MobileAppSection className={className}>
      <HeroCopySection>
        <Heading>Unleash your inner Stoic by signing up and learning from anywhere</Heading>
        <Subheading>
          Take Stoicism with you wherever you go by downloading Stoic Bible on your
          phone or tablet today and start learning, practicing, and mastering life-changing
          Stoic wisdom that will help you improve all areas of your life.
        </Subheading>
        <PlatformBadges>
          <BadgeLink
            href={appStoreAppLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Badge src="/apple-badge.png" alt="apple badge" />
          </BadgeLink>
          <BadgeLink
            href={gplayAppLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            <GPlayBadge src="/google-play-badge.png" alt="google play badge" />
          </BadgeLink>
        </PlatformBadges>
      </HeroCopySection>
      {!isMobile && <HeroImage src="/MobileHero.png" alt="Mobile Apps" />}
    </MobileAppSection>
  );
};

export default MobileAppHero;
