import React, { useEffect } from 'react';
import withRedux from 'common/page-layout/withRedux';
import { useAppSelector } from 'common/hooks/state-hooks';
import { NextSeo } from 'next-seo';
import { Mixpanel, eventNames } from 'common/utils/mixpanel';
import { selectUser } from 'modules/auth';
import LandingPage from 'static/landing/pages/LandingPage';


const baseURL = process.env.NEXT_PUBLIC_FRONTEND_BASEURL;


const Index = () => {  
  const user = useAppSelector(selectUser);
  const isUserAuthenticated = !!user;
  useEffect(() => {
    Mixpanel.trackEvent(eventNames.view_landing_page);
  }, []);

  return (
    <>
      <NextSeo
        title="Stoic Bible - Level-up with Ancient Wisdom for Modern Life."
        description="Stoic Bible will help you become wiser,
        more tranquil and resilient by making Stoicism easy
        for you to learn and practice every day. It is your best app for learning
         Stoicism and cultivating mental and emotional well-being. Use it to develop
          your calm, focus, and resilience."
        canonical={baseURL}
        openGraph={{
          type: 'website',
          title: 'Stoic Bible - Level-up with Ancient Wisdom for Modern Life.',
          description:
            'Stoic Bible will help you become wiser, more tranquil and resilient '
            + 'by making Stoicism easy for you to learn and practice every day. '
            + 'It is your best app for learning Stoicism and cultivating mental and'
            + ' emotional well-being. Use it to develop your calm, focus, and resilience.',
          locale: 'en_IE',
          url: baseURL,
          site_name: 'Stoic Bible',
          images: [
            {
              url: '/Stoic Bible Logo Mark.svg',
              width: 800,
              height: 600,
              alt: 'stoic bible',
            },
          ],
        }}
        twitter={{
          handle: '@stoicbible',
          site: '@stoicbible',
          cardType: 'summary',
        }}
      />
      <LandingPage isUserAuthenticated={isUserAuthenticated} />
    </>
  );
};


export default withRedux(Index, { ssr: false });
