import type { ThemeProps, DefaultTheme } from 'styled-components';
import styled, { css } from 'styled-components';
import { ArrowRight } from 'styled-icons/heroicons-outline';
import {
  isTablet,
  isTabletOrLarger
} from 'app/globalStyles/screens';
import { setFlex, setFont } from 'app/globalStyles/variables';
import DefaultHeader from 'common/page-layout/Header/Header';
import Link from 'next/link';

export const HeroSection = styled.section`
  position: relative;
  background-image: url('/stoic bible render front.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  padding-top: 80vw;
  margin-bottom: 70px;
  ${isTabletOrLarger(css`
    padding-top: unset;
    height: 50.7291666667vw;
    margin-bottom: 8.4895833333vw;
  `)};
`;

export const HeroImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 117.3333333333vw;
  width: 186.6666666667vw;
  background-image: url('/stoic bible render front.png');
  background-repeat: no-repeat;
  background-size: contain;
`;

export const HeroMain = styled.div`
  flex-direction: column;
  ${setFlex()};
  padding-left: 5px;
  height: unset;
  border-radius: 50px;
  border-top: transparent;
  ${isTabletOrLarger(css`
    ${isTablet(css`
      width: 60vw;
      margin-top: 50vh;
    `)};
    ${setFlex({ x: 'flex-end', y: 'start' })};
    position: absolute;
    left: 12vw;
    bottom: 12vw;
    top: initial;
    height: 19.7916666667vw;
    background: initial;
    border-radius: initial;
    border-top: initial;
    -webkit-box-shadow: initial;
    -moz-box-shadow: initial;
    box-shadow: initial;
  `)};
`;

export const HeroHeader = styled.h1`
  font-weight: 500;
  font-family: ${setFont.headings};
  color: ${({ theme: { colors } }) => colors.colorTitle};
  font-size: 8.5vw;
  line-height: 13.0666666667vw;
  text-align: center;
  margin-bottom: 1.8rem;
  letter-spacing: -0.1362962667vw;
  width: 90%;
  z-index: 1;
  background: ${({ theme: { colors } }) => colors.colorBackground};
  ${({ theme: { isDarkTheme } }) => (isDarkTheme
    ? `
      -webkit-box-shadow: 6px -46px 90px 40px rgba(18, 18, 18, 1);
      -moz-box-shadow: 6px -46px 90px 40px rgba(18, 18, 18, 1);
      box-shadow: 6px -46px 90px 40px rgba(18, 18, 18, 1);`
    : `
      -webkit-box-shadow: 6px -46px 90px 40px rgba(255, 255, 255, 1);
      -moz-box-shadow: 6px -46px 90px 40px rgba(255, 255, 255, 1);
      box-shadow: 6px -46px 90px 40px rgba(255, 255, 255, 1);
  `)};

  ${isTabletOrLarger(css`
    width: 45.3vw;
    font-size: 3vw; // 4.8vw;
    line-height: 4.5vw; // 6.875vw;
    text-align: initial;
    letter-spacing: -0.06655vw;
    margin-bottom: 0.5208333333vw;
  `)};
`;

export const HeroSubHeader = styled.p`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -0.615385px;
    color: ${({ theme: { colors } }) => colors.colorText};
    opacity: 0.8;
    width: 85%;
    text-align: center;
    margin-bottom: 1.8rem;

  ${isTabletOrLarger(css`
      font-size: 1.6vw;
      line-height: 3vw;
      text-align: initial;
      letter-spacing: -0.06209947vw;
      margin-bottom: 2.7604166667vw;
      width: 45.3vw;
  `)};
`;

export const HeroButtonsWrapper = styled.div`
    width: 60%;
    display: flex;
    flex-direction: column;
  ${isTabletOrLarger(css`
    width: 90%;
    display: initial;
  `)};
`;

export const CTA = styled(Link)`
  ${setFlex()};
  display: inline-flex;
  height: 50px;
  text-decoration: none;
  white-space: nowrap;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  width: ${({ block }: ThemeProps<DefaultTheme> & { block: boolean }) =>
    (block ? '100%' : '241px')};
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.3px;
  ${isTabletOrLarger(css`
      min-width: 15vw;
      width: initial;
      font-size: 1.6rem;
      line-height: initial;
      letter-spacing: -0.3px;
  `)};
`;

export const PrimaryCTA = styled<any>(CTA)`
  background: ${({ theme: { colors } }) => colors.colorPrimary1};
  color: ${({ theme: { colors } }) => colors.colorTextWhite};
  font-weight: 500;
  margin: 20px auto 30px auto;
  ${isTabletOrLarger(css`
    margin: initial;
    margin-right: 20px;
  `)};
  ${isTablet`
    width:19vw;
  `};
`;

export const SecondaryCTA = styled<any>(CTA)`
  background: ${({ theme: { colors } }) => colors.colorPrimary4};
  color: ${({ theme: { colors } }) => colors.colorText};
  border: 0.1px solid ${({ theme: { colors } }) => colors.colorBorder};
  font-weight: 500;
  ${isTablet`
    min-width: 20vw;
    width:22vw;
  `};
`;

export const CTAArrow = styled(ArrowRight)`
  width: 18px;
  height: 18px;
  margin-left: 10px;
  color: ${({ theme: { colors } }) => colors.colorText};
  right: 10px;
  ${isTabletOrLarger(css`
    right: initial;
    width: 1.5625vw;
    height: 1.5625vw;
  `)};
`;

export const Header = styled(DefaultHeader)`
  background: transparent;
  position: fixed;
  top: 0;
  left: 5%;
  right: 5%;
`;
