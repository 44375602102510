import React from 'react';
import { routeTo } from 'common/utils/routes';
import styled from 'styled-components';
import {
  CTAArrow,
  PrimaryCTA,
} from 'static/landing/components/_styles_/Hero.styles';
import { isTablet, isTabletOrLarger } from 'app/globalStyles/screens';

export const LearningCTA = styled<any>(PrimaryCTA)`
  margin: 20px;
  background: ${({ theme: { colors } }) => colors.colorPrimary1};
  ${isTablet`
    width:20%;
  `};
  ${isTabletOrLarger`
           margin: 20px;
         `};
`;

export const ReadingCTAArrow = styled<any>(CTAArrow)`
  color: white;
`;

const StartLearningCTA = ({
  className,
  showArrow = true,
}: {
  className?: string;
  showArrow?: boolean;
}): JSX.Element => (
  <LearningCTA href={routeTo.read} className={className}>
    <>
      <span>Start Learning</span>
      {showArrow && <ReadingCTAArrow />}
    </>
  </LearningCTA>
);

export default StartLearningCTA;
