import { isTabletOrLarger } from 'app/globalStyles/screens';
import { setFont } from 'app/globalStyles/variables';
import React from 'react';
import styled from 'styled-components';
import StartLearningCTA from './StartLearningCTA';

const QuoteContainer = styled.div`
    margin: 80px auto;
    display: flex;
    width: 80%;
    flex-direction: column;
    align-items: center;
    ${isTabletOrLarger`
        width: 800px;
    `}
`;

const Quote = styled.p`
    font-family: ${setFont.headings};
    font-size: 3.5rem;
    text-align: center;
`;

const Author = styled<any>(Quote)``;

const QuoteSection = () => (
  <QuoteContainer>
    <Quote>“How long are you going to wait before you demand the best for yourself?”</Quote>
    <Author>– Epictetus</Author>
    <StartLearningCTA showArrow={false} />
  </QuoteContainer>
);

export default QuoteSection;
