import React from 'react';
import Layout from 'common/page-layout/Layout';
import Hero from 'static/landing/components/HeroSection';
import BooksSection from 'static/landing/components/BooksSection';
import FeaturesSection from 'static/landing/components/FeaturesSection';
import NewFeaturesSection from 'static/landing/components/NewFeaturesSection';
import Testimonials from 'static/landing/components/Testimonials';
import MobileAppHero from 'static/landing/components/MobileAppHero';
import { Header } from 'static/landing/components/_styles_/Hero.styles';
import QuoteSection from '../components/QuoteSection';

type Props = {
  isUserAuthenticated: boolean;
};

const LandingPage = ({ isUserAuthenticated }: Props): JSX.Element => (
  <Layout header={<Header allowSearch={false} />}>
    <Hero isUserAuthenticated={isUserAuthenticated} />
    <FeaturesSection />
    <BooksSection />
    <NewFeaturesSection />
    <MobileAppHero />
    <Testimonials />
    <QuoteSection />
  </Layout>
);

export default LandingPage;
